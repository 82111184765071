import logo from './logo.svg';
import './App.css';

import DaApp from './DaApp.js';
function App() {
  return (
    <DaApp />
  );
}

export default App;
